import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { get_session_status } from '../../core/utils/session-collection-progress';

class ComplianceTriageObject extends Component {
  constructor(props) {
    super(props);
    this.getEndDate = this.getEndDate.bind(this);
    this.getColorStyles = this.getColorStyles.bind(this);
    this.formatDate = this.formatDate.bind(this);
    this.routeToSession = this.routeToSession.bind(this);
  }

  /**
   * Formats a datestring to calculate end date of a session
   * @param {string} startDate Session start date
   * @param {number} daysAfter Session period
   * @returns Formatted End-Date string
   */
  getEndDate(startDate, daysAfter) {
    const endDate = new Date(startDate);
    endDate.setDate(endDate.getDate() + (daysAfter - 1));
    return `${endDate.getMonth() + 1}/${endDate.getDate()}/${endDate.getFullYear()}`;
  }

  /**
   * Formats the start date
   * @param {string} date Date string 
   * @returns Formatted Date String
   */
  formatDate(date) {
    const dateObj = new Date(date);
    return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
  }

  /**
   * Determines the styles based on compliance data
   * @returns Object containing color styles and hover styles
   */
  getColorStyles() {
    let progress = this.props.housekeeping.collection_progress;
    let setsCollected = this.props.sets.length;
    let threshold = this.props.rules.min_threshold;
    let evaluation = Object.keys(this.props.evaluation).length > 0;

    let sessionStatus = get_session_status(setsCollected, threshold, progress, evaluation);
    if (sessionStatus === 2) {
      return {
        baseColor: "bg-red-300",
        hoverColor: "hover:bg-red-400",
        borderColor: "hover:border-red-500",
      };
    } else if (sessionStatus === 1) {
      return {
        baseColor: "bg-yellow-300",
        hoverColor: "hover:bg-yellow-400",
        borderColor: "hover:border-yellow-500",
      };
    }
    return {
      baseColor: "bg-green-300",
      hoverColor: "hover:bg-green-400",
      borderColor: "hover:border-green-500",
    };
  }

  routeToSession() {
    if (this.props.pid) {
      const path = `/portal/patient-data/${this.props.pid}/sessions?sid=${this.props.id}`;
      this.props.history.push(path);
    }
  }

  render() {
    const { baseColor, hoverColor, borderColor } = this.getColorStyles();

    return (
      <div
        className={`cursor-pointer rounded-lg w-full p-5 mt-4 font-bold text-xs space-y-2 ${baseColor} ${hoverColor} ${borderColor} shadow-xl`}
        onClick={this.routeToSession}
      >
        <div className="flex justify-center items-center">
          <label className="font-bold">{this.props.name}</label>
        </div>
        <div className="flex justify-center items-center">
          <label>Start Date: {this.formatDate(this.props.start_date)}</label>
        </div>
        <div className="flex justify-center items-center">
          <label>End Date: {this.getEndDate(this.props.start_date, this.props.rules.period)}</label>
        </div>
        <div className="flex justify-center items-center text-center">
          <label>Session Name: {this.props.rules.title}</label>
        </div>
      </div>
    );
  }
}

export default withRouter(ComplianceTriageObject);
