import {React, Component} from 'react';
import { LineChart } from './LineChart';
import { AccDistribution } from './AccDistribution';
import { BarChart } from './BarChart';
import { Histogram } from './Histogram';
import { PeakFlow } from './PeakFlow';
import { FORM_OVER_TIME, CLASSIFICATION_CONFIDENCE, PEAK_FLOW, PEAK_FLOW_GRAPH, TRIP_COUNT, SHAKE_COUNT, FORM_GRAPH, GAIT_VELOCITY_GRAPH, GAIT_VELOCITY_GRAPH_NAME, CLASSIFICATION_GRAPH} from '../../../../resources/Config';

/**
 * Component to display different type of charts
 * @property {Object} data Balance Data
 * @property {string} default Key of the graph to display by default
 * @property {Object} graphSet Dropdown options to display a choice of graphs
 */
class Graph extends Component {

  constructor(props) {
    super(props);
    /**
     * Graph component state
     * @type {Object}
     * @property {string} graph Graph to display
     * @property {boolean} granularDataOn Display granular data component?
     * @property {string} granularDataDate Date of dataset
     * @property {string} granularData Granular data to display in component
     * @property {boolean} flaggedData Was the data flagged by Balance API
     */
    this.state = {
      granularDataOn: false,
      granularDataDate: "",
      granularData: [],
      flaggedData: false,
    };

    this.formatForAcc = this.formatForAcc.bind(this);
    this.formatForHistogram = this.formatForHistogram.bind(this);
    this.showGranularData = this.showGranularData.bind(this);
  }

  /**
   * Shows the granular data component
   * @param {boolean} show Show component
   * @param {string} date Date of data to display
   * @param {Object} data Data to display
   * @param {boolean} flagged Data flagged?
   */
  showGranularData(show, date, data, flagged) {
    console.log("Call a callback function which shows the granular data");
    this.props.showGranularData(date,data,flagged);
    this.setState({...this.state, 
      granularDataOn: show,
      granularDataDate: date,
      granularData: data,
      flaggedData: flagged
    });
  }

  /**
   * Formats distribution as percentages
   * @param {Array<Object>} data Exercise Data Array
   * @returns Formatted exercise data array
   */
  formatForAcc(data) {
    let acc_data = [];
    for (var i=0; i<data.length; i++) {
      var set = data[i];
      var temp = {
        date: set.date,
        sagittal: 100 * set.acc_distribution[0],
        axial: 100 * set.acc_distribution[1],
        coronal: 100 * set.acc_distribution[2]
      };
      acc_data = [...acc_data, temp];
    }
    return acc_data;
  }

  /**
   * Filters out data that is respiratory related
   * @param {Array<Object>} data Exercise Data Array
   * @returns Filtered array of Objects
   */
  formatForHistogram(data) {
    let hist_data = [];
    for (var i=0; i<data.length; i++) {
      var tmp = data[i];
      // Ensure that the dataset is relevant
      if(tmp.exercise !== 'sumo') continue;
      hist_data.push({ y: tmp.shake_count });
    }
    return hist_data;
  }

  render() {
    return(
      <>
        <div className="items-center justify-center px-4 max-h-full h-screen w-full mx-4 rounded-3xl overflow-auto">
          <div className="rounded-3xl mx-4">
            {this.props.graph == "accDistribution" ? <AccDistribution data={this.formatForAcc(this.props.data)} /> : null}
            {this.props.graph == "formOverTime" ? 
                <LineChart 
                  data={this.props.data} 
                  labels={FORM_OVER_TIME} 
                  name={FORM_GRAPH}
                  legendSide={'Right'}
                  showGranularData={this.showGranularData}/> : null
            }
            {this.props.graph == "gaitVelocity" ? 
                <LineChart 
                  data={this.props.data} 
                  labels={GAIT_VELOCITY_GRAPH} 
                  name={GAIT_VELOCITY_GRAPH_NAME}
                  legendSide={'Right'}
                  showGranularData={this.showGranularData}/> : null
            }
            {this.props.graph == "classificationConfidence" ? 
                <LineChart 
                  data={this.props.data} 
                  labels={CLASSIFICATION_CONFIDENCE} 
                  name={CLASSIFICATION_GRAPH}
                  legendSide={'Right'}
                  showGranularData={this.showGranularData}/> : null
            }
            {this.props.graph == "peakFlow" ? 
                <PeakFlow 
                  threshold={this.props.threshold.threshold}
                  data={this.props.data} 
                  labels={PEAK_FLOW} 
                  name={PEAK_FLOW_GRAPH}
                  legendSide={'Right'}
                  showGranularData={this.showGranularData}/> : null
            }
            {this.props.graph == "tripCount" ? <BarChart data={this.props.data} labels={TRIP_COUNT} /> : null}
            {this.props.graph == "inhalerShake" ? <Histogram data={this.formatForHistogram(this.props.data)} labels={SHAKE_COUNT}/> : null}
          </div>
        </div>
      </>
    );
    }
};

export default Graph;
