import React, { Component } from 'react';
import { createPostRequest } from '../../auth/utils/axios-utils';
import { generate_file } from '../utils/data-download';
import { DATA_KEY } from '../../../resources/Vault';

class ViewGranularData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: this.props.data,
      addingNote: false,
      noteSubject: '',
      noteContent: '',
      error: '',
      notes: this.props.notes
    };
    this.handleNoteSubmit = this.handleNoteSubmit.bind(this);
    this.downloadData = this.downloadData.bind(this);
  }

  componentDidMount() {
    if (this.props.visible) {
      document.body.style.overflow = 'hidden';
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.visible !== this.props.visible) {
      document.body.style.overflow = this.props.visible ? 'hidden' : 'auto';
    }
    if (prevProps.notes !== this.props.notes) {
      this.setState({ notes: this.props.notes });
    }
  }

  componentWillUnmount() {
    document.body.style.overflow = 'auto';
  }

  handleAddNoteClick = () => {
    this.setState({ addingNote: true, error: '' });
  };

  handleCancelNote = () => {
    this.setState({ addingNote: false, noteSubject: '', noteContent: '', error: '' });
  };

  async handleNoteSubmit() {
    const { noteSubject, noteContent } = this.state;
    if (!noteSubject.trim() || !noteContent.trim()) {
      this.setState({ error: 'Both fields are required.' });
      return;
    }

    // Make the call to the backend to add the note
    const note = {
      subject: noteSubject,
      content: noteContent,
      tag: "data",
      context: this.props.data.eid
    };
    const path = "/addNote/" + this.props.pid;
    const headers = { "x-access-token": localStorage.getItem("auth_token") };
    const body = { info: note };
    try {
      const addNoteResponse = await createPostRequest(path, headers, body);

      // This logic is dumb, figure out how to improve it
      const keys = Object.keys(addNoteResponse);
      let newNote = {};
      keys.forEach(key => {
        newNote = addNoteResponse[key];
      });
      this.setState({ 
        notes: [...this.state.notes, newNote],
        addingNote: false,
        noteSubject: '',
        noteContent: '',
        error: '' });
    } catch (error) {
      this.setState({ error: 'There was an error adding the note' });
      return;
    }
  };

  renderNotesSection() {
    const { notes } = this.state;
    if (!notes || notes.length === 0) return null;

    // Filter the notes based on the EID that's being shwon
    const filteredNotes = notes.filter(note => note.context === this.props.data.eid);

    return (
      <div className="mt-4">
        <div className="border-t border-gray-300 py-4">
          <h2 className="text-lg font-semibold text-gray-700 ml-4">Notes</h2>
        </div>
        <div className="space-y-4 p-4 -mt-6">
          {filteredNotes.map((note) => (
            <div key={note.nid} className="p-4 border rounded-md bg-gray-100">
              <div className="flex justify-between items-center mb-2">
                <span className="text-sm font-medium text-gray-700">{note.subject}</span>
                <span className="text-xs text-gray-500">{note.date}</span>
              </div>
              <p className="text-sm text-gray-800 mb-1">{note.content}</p>
              <span className="text-xs text-gray-600 italic">- {note.author}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }

    /**
   * Creates a file name based on patient data and triggers file
   * to download
   */
    downloadData() {
      // Format date as ISO 8601
      let date = new Date(this.state.data.date).toISOString();
      // Replace colons with dashes
      date = date.replaceAll(':', '-');
      // Chop off the milliseconds
      date = date.slice(0, -5);
      // Get patient data from session storage
      const patient = JSON.parse(sessionStorage.getItem(DATA_KEY));
      const filename = patient.firstName + "-" + patient.lastName
                        + "-" + this.state.data.side + "-" + this.state.data.exercise
                        + "-" + date;
      
      // Format data for download
      const payload = {
        data: this.state.data.data
      };
      generate_file(filename, payload);
    }

  render() {
    if (!this.props.visible) return null;

    const {
      form,
      classification,
      confidence,
      acc_distribution,
      side,
      gait_velocity,
    } = this.props.data;
    const { flagged, closeModal } = this.props;
    const { addingNote, noteSubject, noteContent, error } = this.state;

    return (
      <div className="fixed mt-10 inset-0 bg-black bg-opacity-30 backdrop-blur-sm flex justify-center items-center">
        <div
          className={`bg-white w-1/4 overflow-auto pb-6 rounded h-5/6 ${
            flagged ? 'border-2 border-rose-400' : 'shadow-lg'
          }`}
        >
          <div className="sticky top-0 z-40 flex items-center border-b-4 border-gray-300 bg-gray-200 p-3 relative">
            <h1 className="text-xl font-bold flex-grow text-center">
              Granular Data
            </h1>
            <button
              className="absolute right-4 px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-full hover:bg-red-600 focus:ring-2 focus:ring-red-500 shadow-md"
              onClick={closeModal}
              aria-label="Close Modal"
            >
              ✕
            </button>
          </div>

          {flagged && (
            <div className="p-4 bg-rose-100 text-rose-600 border-b border-rose-200 flex items-start">
              <span className="text-2xl mr-2">⚠️</span>
              <div>
                <h2 className="text-md font-semibold">Data Was Flagged Because:</h2>
                {gait_velocity < 0.5 && (
                  <p className="text-sm font-medium">• Velocity Too Low</p>
                )}
                {gait_velocity > 1.2 && (
                  <p className="text-sm font-medium">• Velocity Too High</p>
                )}
                {confidence < 80 && (
                  <p className="text-sm font-medium">• Model Confidence Too Low</p>
                )}
                {classification === 'inconsistent' && (
                  <p className="text-sm font-medium">• Classification is Inconsistent</p>
                )}
              </div>
            </div>
          )}

          <div className="p-4">
            <div className="mb-4">
              <span className="font-bold">Form:</span>{' '}
              <span>{form.toFixed(2)}%</span>
            </div>
            <div className="mb-4">
              <span className="font-bold">Classification:</span>{' '}
              <span className={classification === 'inconsistent' ? 'text-red-600 font-semibold' : ''}>
                {classification}
              </span>
              {classification === 'inconsistent' && (
                <p className="text-sm text-rose-600 mt-1">• Classification is Inconsistent</p>
              )}
            </div>
            <div className="mb-4">
              <span className="font-bold">Confidence:</span>{' '}
              <span className={confidence < 80 ? 'text-red-600 font-semibold' : ''}>
                {confidence.toFixed(2)}%
              </span>
              {confidence < 80 && (
                <p className="text-sm text-rose-600 mt-1">• Model Confidence Too Low</p>
              )}
            </div>
            <div className="mb-4">
              <span className="font-bold">Saggital:</span>{' '}
              <span>{(acc_distribution[0] * 100).toFixed(2)}%</span>
            </div>
            <div className="mb-4">
              <span className="font-bold">Coronal:</span>{' '}
              <span>{(acc_distribution[1] * 100).toFixed(2)}%</span>
            </div>
            <div className="mb-4">
              <span className="font-bold">Axial:</span>{' '}
              <span>{(acc_distribution[2] * 100).toFixed(2)}%</span>
            </div>
            <div className="mb-4">
              <span className="font-bold">Side Worn:</span>{' '}
              <span>{side}</span>
            </div>
            <div className="mb-4">
              <span className="font-bold">Gait Velocity:</span>{' '}
              <span
                className={
                  gait_velocity < 0.5 || gait_velocity > 1.2 ? 'text-red-600 font-semibold' : ''
                }
              >
                {gait_velocity.toFixed(2)} m/s
              </span>
              {gait_velocity < 0.5 && (
                <p className="text-sm text-rose-600 mt-1">• Velocity Too Low</p>
              )}
              {gait_velocity > 1.2 && (
                <p className="text-sm text-rose-600 mt-1">• Velocity Too High</p>
              )}
            </div>
            <button 
              className= "mt-4 inline-flex items-center justify-center px-8 py-2 rounded-md bg-primary hover:bg-accent w-half h-12 text-base font-small leading-6 text-white"
              onClick={() => this.downloadData()}>
                Download IMU Data
            </button>
          </div>

          {/* Render Notes Section */}
          {this.renderNotesSection()}

          {/* Add Note Section */}
          <div className="p-4">
            {!addingNote ? (
              <button
                onClick={this.handleAddNoteClick}
                className="w-full bg-blue-500 text-white font-medium py-2 px-4 rounded hover:bg-blue-600"
              >
                Add Note
              </button>
            ) : (
              <div>
                <div className="mb-3">
                  <label className="block text-sm font-semibold mb-1">Subject:</label>
                  <input
                    type="text"
                    className="w-full border rounded px-2 py-1"
                    value={noteSubject}
                    onChange={(e) => this.setState({ noteSubject: e.target.value })}
                  />
                </div>
                <div className="mb-3">
                  <label className="block text-sm font-semibold mb-1">Note:</label>
                  <textarea
                    className="w-full border rounded px-2 py-1"
                    rows="3"
                    value={noteContent}
                    onChange={(e) => this.setState({ noteContent: e.target.value })}
                  />
                </div>
                {error && <p className="text-sm text-red-500">{error}</p>}
                <div className="flex justify-end space-x-2">
                  <button
                    onClick={this.handleCancelNote}
                    className="bg-gray-300 text-gray-800 font-medium py-1 px-3 rounded hover:bg-gray-400"
                  >
                    Cancel
                  </button>
                  <button
                    onClick={this.handleNoteSubmit}
                    className="bg-green-500 text-white font-medium py-1 px-3 rounded hover:bg-green-600"
                  >
                    Submit
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ViewGranularData;
