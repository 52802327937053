import {
    Category,
    DateTimeCategory,
    ChartComponent,
    SplineSeries,
    Inject,
    Legend,
    LineSeries,
    SeriesCollectionDirective,
    SeriesDirective,
    Tooltip,
    StripLine,
  } from "@syncfusion/ej2-react-charts";
  import { createGetRequest } from "../../../auth/utils/axios-utils";
  import React, { Component } from "react";
  
  class PeakFlow extends Component {
    constructor(props) {
      super(props);
  
      // X-Axis Configuration
      this.primaryxAxis = {
        valueType: "DateTimeCategory",
        labelFormat: "yMMMdd",
      };
  
      // Y-Axis Configuration with Fixed Range and Strip Lines
      this.primaryYAxis = {
        title: "lpm",
        minimum: 0, // Start of Y-Axis
        maximum: Math.ceil(this.props.threshold / 100) * 100, // End of Y-Axis
        interval: 100, // Tick intervals
        stripLines: [
          {
            visible: true,
            start: this.props.threshold * 0.8,
            end: Math.ceil(this.props.threshold / 100) * 100,
            color: "rgba(0, 128, 0, 0.3)", // Green zone
            text: "High",
            textStyle: { color: "black" },
            zIndex: "Behind",
          },
          {
            start: this.props.threshold * 0.5,
            end: this.props.threshold * 0.8,
            color: "rgba(255, 255, 0, 0.3)", // Yellow zone
            text: "Medium",
            textStyle: { color: "black" },
            zIndex: "Behind",
          },
          {
            start: 0,
            end: this.props.threshold * 0.5,
            color: "rgba(255, 0, 0, 0.3)", // Red zone
            text: "Low",
            textStyle: { color: "black" },
            zIndex: "Behind",
          },
        ],
      };
  
      // Tooltip Configuration
      this.tooltip = {
        enable: true,
        format: "${point.y}",
        fill: "#704223A",
        border: {
          width: 2,
          color: "grey",
        },
      };
  
      // Legend Position (customizable via props)
      let legendSide = props.legendSide || "Top";
      this.legendSettings = { visible: true, position: legendSide };
  
      // Marker Configuration
      this.marker = { visible: true, width: 10, height: 10 };
  
      // Title Style
      this.titlestyle = {
        fontFamily: "Arial",
        fontWeight: "bold",
        color: "#04223A",
        size: "23px",
      };
    }
  
    render() {
      return (
        <>
          <ChartComponent
            title={this.props.labels.title}
            tooltip={this.tooltip}
            legendSettings={this.legendSettings}
            primaryXAxis={this.primaryxAxis}
            primaryYAxis={this.primaryYAxis}
            titleStyle={this.titlestyle}
          >
            <Inject
              services={[
                LineSeries,
                Legend,
                Tooltip,
                SplineSeries,
                Category,
                DateTimeCategory,
                StripLine
              ]}
            />
            <SeriesCollectionDirective>
              <SeriesDirective
                type="Line"
                marker={this.marker}
                name={this.props.labels.title}
                width="5"
                fill="#04223A"
                dataSource={this.props.data}
                xName={this.props.labels.dataKeyX}
                yName={this.props.labels.dataKeyY}
              />
            </SeriesCollectionDirective>
          </ChartComponent>
        </>
      );
    }
  }
  
  export { PeakFlow };
  